/* Grids and Layouts */

:root {
    --layout-margin: theme('spacing.lg');
    --layout-spill: max(1.5rem, 5vw);
    --layout-prose: clamp(30rem, 50vw, 45rem);
}
@screen xs {
    :root {
        --layout-margin: theme('spacing.3xl');
    }
}

/* Simple Container */
.container {
    padding-left: var(--layout-margin);
    padding-right: var(--layout-margin);
}

/* Fine-grained Container via grid */
.layout {
    display: grid;
    grid-template-columns:
        var(--layout-margin)
        minmax(0, 1fr)
        var(--layout-margin);
}
@screen sm {
    .layout {
        grid-template-columns:
            var(--layout-margin)
            minmax(var(--layout-spill), 1fr)
            var(--layout-spill)
            var(--layout-prose)
            var(--layout-spill)
            minmax(var(--layout-spill), 1fr)
            var(--layout-margin);
    }
}

@layer utilities {
    /* Edge to Edge */
    .layout-bleed {
        @apply col-span-full;
    }
    /* Left Edge to Container */
    .layout-bleed-left {
        @apply col-start-1 col-span-2;
        @apply sm:col-start-1 sm:col-span-6;
    }
    /* Container to Right Edge */
    .layout-bleed-right {
        @apply col-start-2 col-span-2;
        @apply sm:col-start-2 sm:col-span-6;
    }
    /* Container */
    .layout-main {
        @apply col-start-2 col-span-1;
        @apply sm:col-start-2 sm:col-span-5;
    }
    /* Prose Container */
    .layout-content {
        @apply col-start-2 col-span-1;
        @apply sm:col-start-4 sm:col-span-1;
    }
    /* Left Edge to Prose Container */
    .layout-content-bleed-left {
        @apply col-start-1 col-span-2;
        @apply sm:col-start-1 sm:col-span-4;
    }
    /* Prose Container to Right Edge */
    .layout-content-bleed-right {
        @apply col-start-2 col-span-2;
        @apply sm:col-start-4 sm:col-span-4;
    }
    /* Left Container to Prose Container */
    .layout-content-main-left {
        @apply col-start-2 col-span-2;
        @apply sm:col-start-2 sm:col-span-3;
    }
    /* Prose Container to Right Container */
    .layout-content-main-right {
        @apply col-start-2 col-span-1;
        @apply sm:col-start-4 sm:col-span-3;
    }
    /* Prose Container with Margins */
    .layout-spill {
        @apply col-start-2 col-span-1;
        @apply sm:col-start-3 sm:col-span-3;
    }
    /* Prose Container with Left Margin */
    .layout-spill-left {
        @apply col-start-2 col-span-1;
        @apply sm:col-start-3 sm:col-span-2;
    }
    /* Prose Container with Right Margin */
    .layout-spill-right {
        @apply col-start-2 col-span-1;
        @apply sm:col-start-4 sm:col-span-2;
    }
}

/* Work Case Studies layout options */
.grid-area-var {
    grid-area: var(--grid-area);
}
.grid-work-1 {
    grid-template-columns: 0 2fr 3fr 2fr 0;
    grid-template-areas:
        'title  title  title  .      .     '
        '.      group1 group1 group1 group1'
        'group2 group2 group2 group2 .     ';
}
@screen sm {
    .grid-work-1 {
        grid-template-columns: 1fr 7fr 2fr 2fr 7fr;
        grid-template-areas:
            'title title  .      group2 group2'
            '.     .      .      group2 group2'
            '.     group1 group1 group2 group2'
            '.     group1 group1 group2 group2'
            '.     group1 group1 .      .     '
            '.     group1 group1 .      .     ';
    }
}

.grid-work-2 {
    grid-template-columns: 0.5fr 2fr 3fr 2fr 0.5fr;
    grid-template-areas:
        'title    title    title   .       .     '
        '.        group1   group1  group1  group1'
        'group2   group2   group2  group2  .     ';
}
@screen sm {
    .grid-work-2 {
        grid-template-columns: 1fr 7fr 2fr 2fr 7fr;
        grid-template-areas:
            'title title  .      .      .     '
            '.     group1 group1 .      .     '
            '.     group1 group1 group2 group2'
            '.     group1 group1 group2 group2'
            '.     group1 group1 .      .     ';
    }
}
