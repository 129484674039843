/* Additional Utilities */

/* Force an aspect ratio on an element */
.aspect-box:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
}
.aspect-box:after {
    content: '';
    display: table;
    clear: both;
}
.aspect-ratio-1-1:before {
    padding-top: 100%;
}
.aspect-ratio-16-9:before {
    padding-top: 56.25%;
}
.aspect-ratio-3-2:before {
    padding-top: 66.67%;
}

/* Hide overflowing content behind a fade */
.overflow-to-fade {
    @apply relative overflow-hidden whitespace-nowrap pr-8;
    &:after {
        content: '';
        @apply block w-8 h-full absolute right-0 top-0;
        @apply bg-gradient-to-r from-transparent to-gray-100 dark:to-gray-800;
    }
}

/* Writing Modes */
@layer utilities {
    .vertical-rl {
        writing-mode: vertical-rl;
    }
    .vertical-lr {
        writing-mode: vertical-lr;
    }
}
