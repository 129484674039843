/* Custom Components */

/* Fluid embed iframes */
.embed-16-9,
.responsive-embed {
    position: relative;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
.embed-16-9 {
    padding-bottom: 56.25%;
}

/* Lazyloading images etc. */
.lazyload,
.lazyloading {
    transition: all 0.3s;
    opacity: 0.3;
    background: transparent no-repeat scroll center center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='38' height='38' xmlns='http://www.w3.org/2000/svg' stroke='%23CCCCCC'%3E%3Cg transform='translate(1 1)' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18A18 18 0 0 0 18 0'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 25%;
}
.lazyloaded {
    transition: all 0.3s;
    opacity: 1;
    background-image: none;
}

/* Drop-cap */
.drop-cap {
    @apply float-left mr-xs sm:mr-base relative z-0;
    @apply text-7xl sm:text-4xl leading-none not-italic;
    @apply text-gray-700;
    &:before {
        @apply absolute z-[-1] right-0 sm:left-1/2 sm:top-1/2;
        @apply w-auto h-[157%] sm:min-w-[157%] sm:min-h-[157%] sm:h-auto aspect-square;
        @apply -translate-y-[30%] translate-x-[7%] sm:-translate-x-[60%] sm:-translate-y-[60%];
        @apply bg-yellow rounded-full;
        content: '';
    }
    &:after {
        content: '';
        display: block;
        margin-top: -0.1em;
    }
    &.letter-A,
    &.letter-H,
    &.letter-N,
    &.letter-W {
        @apply sm:mr-sm;
    }
    &.letter-I {
        @apply sm:mr-lg;
    }
    a:hover &:before {
        @apply bg-gray-300;
    }
}

/* Home Page Cover */
.clip-cover {
    clip-path: circle(farthest-side at 27% -45%);
    @apply relative z-5;
}
.clip-cover-shadow {
    clip-path: circle(farthest-side at 32% -45%);
    transform: translateY(1%);
    @apply absolute inset-0 bg-yellow z-0;
}
@screen sm {
    .clip-cover {
        clip-path: circle(farthest-side at 38% -150%);
    }
    .clip-cover-shadow {
        clip-path: circle(farthest-side at 39% -150%);
    }
}

.yellow-circle {
    @apply aspect-square bg-yellow rounded-full;
}

/* Jobs Meta circle wrap */
.shape-outside-circle-jobsMeta {
    shape-outside: circle(59.09% at 66.61% 29.49%);
}
@screen sm {
    .shape-outside-circle-jobsMeta {
        shape-outside: circle(66.51% at 72.62% 26.99%);
    }
}
