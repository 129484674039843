/* Forms */

.contact-form {
    input,
    textarea {
        @apply w-full border border-gray-300 bg-white rounded;
        @apply dark:bg-gray-900 dark:border-gray-700;
    }

    label {
        @apply font-title cursor-pointer;
    }
}
