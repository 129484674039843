/*
 * Base
 */

@layer base {
    @font-face {
        font-family: 'messina-serif-web';
        src: url('../fonts/MessinaSerif_Variable_Upright_Web.woff2')
                format('woff2-variations'),
            url('../fonts/MessinaSerif_Variable_Upright_Web.woff')
                format('woff-variations'),
            url('../fonts/MessinaSerifWeb-Regular.woff2') format('woff2'),
            url('../fonts/MessinaSerifWeb-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'messina-serif-web';
        src: url('../fonts/MessinaSerif_Variable_Upright_Web.woff2')
                format('woff2-variations'),
            url('../fonts/MessinaSerif_Variable_Upright_Web.woff')
                format('woff-variations'),
            url('../fonts/MessinaSerifWeb-Bold.woff2') format('woff2'),
            url('../fonts/MessinaSerifWeb-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'messina-serif-web';
        src: url('../fonts/MessinaSerif_Variable_Italic_Web.woff2')
                format('woff2-variations'),
            url('../fonts/MessinaSerif_Variable_Italic_Web.woff')
                format('woff-variations'),
            url('../fonts/MessinaSerifWeb-RegularItalic.woff2') format('woff2'),
            url('../fonts/MessinaSerifWeb-RegularItalic.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'messina-serif-web';
        src: url('../fonts/MessinaSerif_Variable_Italic_Web.woff2')
                format('woff2-variations'),
            url('../fonts/MessinaSerif_Variable_Italic_Web.woff')
                format('woff-variations'),
            url('../fonts/MessinaSerifWeb-BoldItalic.woff2') format('woff2'),
            url('../fonts/MessinaSerifWeb-BoldItalic.woff') format('woff');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }
    @supports (font-variation-settings: 'wght' 500) {
        @font-face {
            font-family: 'messina-serif-web';
            src: url('../fonts/MessinaSerif_Variable_Upright_Web.woff2')
                    format('woff2-variations'),
                url('../fonts/MessinaSerif_Variable_Upright_Web.woff')
                    format('woff-variations');
            font-weight: 1 1000;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: 'messina-serif-web';
            src: url('../fonts/MessinaSerif_Variable_Italic_Web.woff2')
                    format('woff2-variations'),
                url('../fonts/MessinaSerif_Variable_Italic_Web.woff')
                    format('woff-variations');
            font-weight: 1 1000;
            font-style: italic;
            font-display: swap;
        }
    }
}
@layer utilities {
    @supports (font-variation-settings: 'wght' 500) {
        .font-normal {
            font-variation-settings: 'wght' 500;
        }
        .font-semibold {
            font-variation-settings: 'wght' 748;
        }
        .font-bold {
            font-variation-settings: 'wght' 1000;
        }
    }
}

/* Weights */
body {
    @apply font-normal;
}
b,
strong {
    @apply font-bold;
}

/* Typefaces */
body {
    @apply font-prose text-base;
}
h1,
h2,
h3,
h4,
h5 {
    @apply font-bold font-title;
}

.miranj-wf-active {
    .font-prose,
    body {
        @apply font-prose-wf subpixel-antialiased;
    }
    .font-title,
    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply font-title-wf antialiased;
    }
}

/* Links */
a {
    @apply text-inherit no-underline;
}
a:hover {
    @apply underline;
}
a.block-link {
    &:hover {
        @apply no-underline;
    }
}
.link-underline-highlight,
.link-underline-subtle {
    @apply underline decoration-1 underline-offset-2 decoration-gray-700/40 dark:decoration-gray-100/40;
    @apply outline outline-0 outline-black/0;
    @apply rounded-sm transition-all;
    @apply focus:bg-black/10 focus:decoration-gray-700/0;
    @apply dark:focus:bg-white/10 dark:focus:decoration-gray-100/0;
    @apply focus:outline-2 focus:outline-black/40 dark:focus:outline-white/40;
    @apply hover:bg-black/10 hover:decoration-gray-700/0;
    @apply dark:hover:bg-white/10 hover:decoration-gray-100/0;
    @apply hover:outline-4 hover:outline-black/10 dark:hover:outline-white/10;
}
.link-underline-highlight {
    @apply decoration-[0.07em] underline-offset-[0.13em] decoration-yellow;
    @apply hover:decoration-yellow/0;
}

/* Text Styles */
.title-h1 {
    @apply text-xl leading-just;
}
.title-h2 {
    @apply text-lg;
}
.prose-body {
    @apply text-base;
}
.prose-meta {
    @apply text-sm leading-snug;
}

/* Frills */
.quo {
    margin-left: -0.24em;
}
.dquo {
    margin-left: -0.44em;
}
.tquo {
    margin-left: -0.68em;
}
@screen xs {
    .emojiquo {
        margin-left: -1.525em;
        margin-right: 0.3em;
        @apply inline-block scale-125;
    }
}
abbr,
.caps {
    font-size: 0.95em;
    @apply tracking-wide;
}
abbr .caps {
    font-size: inherit;
    letter-spacing: inherit;
}
a abbr {
    text-decoration: none;
}

/* Anchors */
:is(h1, h2, h3, h4, h5) a.anchor {
    @apply absolute;
    width: 1.25em;
    height: 1.25em;
    margin-left: -1.75em;
    margin-left: 0.25em;
    @apply bg-no-repeat bg-center rounded-full opacity-0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244' /%3E%3C/svg%3E");
    background-size: 60% auto;
    &.copied {
        @apply opacity-100;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3.5' stroke='green'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E");
    }
    &.copied.fadeOut {
        @apply opacity-0;
    }
    &.copied::after {
        position: absolute;
        text-align: center;
        width: 150%;
        left: -25%;
        top: 115%;
        @apply sm:top-1/2 sm:left-full sm:w-auto sm:-translate-y-1/2 sm:translate-x-[0.75em];
        opacity: 0.5;
        content: ' Copied';
        font-size: 0.5em;
    }
}
:is(h1, h2, h3, h4, h5):hover a.anchor {
    @apply opacity-50;
}
:is(h1, h2, h3, h4, h5):hover a.anchor:hover {
    @apply opacity-100;
}

/* Rich Text */
.richtext {
    p,
    pre,
    blockquote,
    dl,
    dd,
    ul,
    ol {
        @apply mt-0 mb-5;
    }
    > p:last-child,
    > pre:last-child,
    > blockquote:last-child,
    > dl:last-child,
    > dd:last-child,
    > ul:last-child,
    > ol:last-child {
        @apply mb-0;
    }
    & + & {
        @apply mt-5;
    }

    h2 {
        @apply mt-14 mb-5;
        @apply text-2xl leading-slight;
    }
    h3 {
        @apply mt-2xl mb-lg;
        @apply text-lg leading-slight;
        &:after {
            @apply block w-xl mt-xs border-yellow border-y-2 sm:border-y-3;
            content: '';
        }
    }
    h4 {
        @apply mt-2xl mb-base text-md leading-tight;
    }
    h5 {
        @apply mt-8;
    }
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child {
        @apply mt-0;
    }

    ol,
    ul {
        margin-left: 1.7em;
    }
    li {
        @apply mb-2;
    }
    li p + ol,
    li p + ul {
        @apply -mt-3;
    }
    li li {
        @apply ml-2;
    }
    ol {
        list-style-type: decimal;
    }
    ul {
        list-style-type: disc;
    }
    li ul {
        list-style-type: circle;
    }
    ol ol {
        list-style-type: lower-alpha;
    }
    ol ol ol {
        list-style-type: lower-roman;
    }

    blockquote {
        padding-left: 1.5em;
        @apply border-l-4 border-gray-300 text-gray-500;
        @apply dark:border-gray-700 dark:text-gray-450;
    }

    code {
        @apply bg-gray-200 dark:bg-gray-750 py-0.5 px-1.5 rounded;
        font-size: 0.85em;
    }
    pre {
        code {
            @apply p-0 bg-transparent rounded-none;
            font-size: 0.8em;
        }
        @apply py-base px-lg bg-gray-200 rounded shadow-inner shadow-gray-300;
        @apply dark:bg-gray-850 dark:shadow-gray-900;
        line-height: 1.35;
        word-wrap: normal;
        overflow: auto;
    }
    dt {
        @apply float-left font-bold;
        &:after {
            content: ' • ';
            @apply inline-block mx-2xs;
            @apply text-yellow scale-150 -translate-y-[7%];
        }
    }
    dt:nth-of-type(even),
    dd:nth-of-type(even) {
        @apply ml-lg;
    }

    hr {
        @apply my-16 w-16 border-yellow border-t-2;
    }

    a {
        @apply link-underline-subtle;
    }
    a.no-underline {
        text-decoration: none;
    }

    strong {
        @apply font-bold;
    }

    .caption {
        @apply -mt-2 mb-6 text-xs text-gray-500 dark:text-gray-450;
    }
    div.stretch,
    div.embed-16-9,
    .panel-1,
    .panel-2,
    .panel-3,
    .panel-4 {
        @apply my-10;
        + .caption {
            @apply -mt-6 mb-8;
        }
    }
    div.stretch:first-child,
    div.embed-16-9:first-child,
    .panel-1:first-child,
    .panel-2:first-child,
    .panel-3:first-child,
    .panel-4:first-child {
        @apply mt-0;
    }
    div.stretch:last-child,
    div.embed-16-9:last-child,
    .panel-1:last-child,
    .panel-2:last-child,
    .panel-3:last-child,
    .panel-4:last-child {
        @apply mt-0;
    }

    .panel-1 {
        img {
            @apply mx-auto;
        }
    }
    .panel-2 {
        @apply flex flex-wrap;
        img {
            @apply w-1/2;
        }
    }
    .panel-3 {
        @apply flex flex-wrap;
        img {
            @apply w-1/2 xs:w-1/3;
        }
    }
    .panel-4 {
        @apply flex flex-wrap;
        img {
            @apply w-1/2 sm:w-1/4;
        }
    }
}

/* Emphasised Links */
.highlight-links a {
    @apply link-underline-highlight;
}

/* Markdown Extra Footnotes */
a.footnote-ref,
a.footnote-backref {
    text-decoration: none;
    @apply px-1.25 py-0.25
        text-xs
        text-gray-500 dark:text-gray-450
        bg-gray-300 dark:bg-gray-700 rounded;
}
a.footnote-backref {
    @apply px-1.5 pt-1;
}
.footnotes {
    font-size: 0.85em;
    hr {
        @apply mb-8;
    }
}
@media (prefers-color-scheme: dark) {
    :is(h1, h2, h3, h4, h5) a.anchor {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='%23c3c0af'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244' /%3E%3C/svg%3E");
    }
}
