/* Base */
html {
    scroll-behavior: smooth;
    @apply bg-gray-700;
    @apply dark:bg-gray-750;
}

/* Defaults */
body {
    @apply bg-gray-100 text-gray-700;
    @apply dark:bg-gray-800 dark:text-gray-350;
}
p {
    @apply mt-0;
}

/* lazysizes auto width calculation */
picture img[data-sizes='auto'] {
    @apply block w-full;
}
picture img.w-auto[data-sizes='auto'] {
    width: auto;
}

/* Hide AlpineJS components until Alpine is ready */
html:not(.has-no-js) [x-cloak] {
    display: none;
}
